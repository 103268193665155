import logo from './title_final_1280_512.png';
import './App.css';
import "./youtubeStyle.css";
import YoutubeEmbed from "./youtubeEmbed";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="main-content">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>Genetic Fluff : Now in Early Access on Steam</h1>
          <h2><a className="link" target="_blank" rel="noreferrer" href="https://store.steampowered.com/app/1403270/Genetic_Fluff/">Genetic Fluff on Steam®</a></h2>
          <h2><a className="link" target="_blank" rel="noreferrer" href="http://growingbrains.com">Published by GrowingBrains.com</a></h2>
          <div className="youtubeStyle">
            <YoutubeEmbed embedId="r7C8WvLvi78" />
          </div>
        </div>
        
      </header>
      
      <div className="copyrightgb">
          <p>Copyright © 2021 <b>Growing Brains LLC</b>. All rights reserved</p>
      </div>
    </div>
  );
}

export default App;
